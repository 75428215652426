import axiosConfig from "./axiosConfig";
const registWithEmail = async (payload) => {
	try {
		console.log(payload);
		const { data } = await axiosConfig.post("/users/signup", payload);
		return data;
	} catch (error) {
		console.error('registWithEmail failed:', error);
		throw error;
	}
}
const loginWithEmail = async (payload) => {
	try {
		const { data } = await axiosConfig.post("/users/login", payload);
		return data;
	} catch (error) {
		console.error('loginWithEmail failed:', error);
		throw error;
	}
}
const verifyTelegramLogin = async (payload) => {
	try {
		console.log("payload:", payload);
		const { data } = await axiosConfig.post("/users/verifyTelegramLogin", payload);
		return data;
	} catch (error) {
		console.error('loginWithEmail failed:', error);
		throw error;
	}
}
export {registWithEmail, loginWithEmail, verifyTelegramLogin };
