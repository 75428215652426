import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { sendIcon } from '../../assets';
import './reportpost.css'
import { reportPost } from '../../features/postSlice';
import useFetch from '../../hooks/useFetch';

const initialForm = { reason: "" };

const ReportPost = ({ close }) => {
  const {
    post: { reportingPost },
  } = useSelector(state => state);

  const [form, setForm] = useState(initialForm);
  const dispatch = useDispatch();
  const customFetch = useFetch();


  const submitHandler = async (event) => {
    event.preventDefault();
    const { reason } = form;
    dispatch(reportPost({customFetch, reason, postId: reportingPost._id}));
    close();
    setForm(initialForm);
  }

  return (
    <article className="createpost gradient-border reportpost">
      <form onSubmit={submitHandler}>
        <textarea
          placeholder="What type of issue are you reporting?"
          value={form.reason}
          onChange={e => setForm({ ...form, reason: e.target.value })}
        />

        <div className="btns">
          <button type="submit" aria-label="submit">
            <img src={sendIcon} alt="send"/>
          </button>
        </div>
      </form>
    </article>
  );
};

export default ReportPost;
