import React, { useEffect, useState } from "react";
import 'react-phone-number-input/style.css';
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";
import PhoneInput from 'react-phone-number-input';
import AuthCode from 'react-auth-code-input';
import {setIsLoading, showModal} from "../../features/modalSlice";
import { loginWithEmail, verifyTelegramLogin } from "../../services/authServices";
import { login } from "../../features/userSlice";
import TelegramLoginButton from 'react-telegram-login';

const Login = ({ setIsRegistering, isActive }) => {
    const [email, setEmail] = useState('');
  	const [password, setPassword] = useState('');
	const [isPhoneLogin, setIsPhoneLogin] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [smsCode, setSmsCode] = useState('');
    const [showSmsInput, setShowSmsInput] = useState(false);

    const dispatch = useDispatch();
    const customFetch = useFetch();

	const handleEmailChange = (e) => setEmail(e.target.value);
  	const handlePasswordChange = (e) => setPassword(e.target.value);
	const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handleSmsCodeChange = (res) => { setSmsCode(res)};

	const handleSubmit = (e) => {
        (async () => {
            e.preventDefault();
            // Handle email/password login
            try {
                const payload = {
                    email: email,
                    password: password
                };
                console.log("login:", payload);
                const userData = await customFetch(loginWithEmail, payload);
                console.log("login:", userData);
                if (userData) {
                    dispatch(login(userData));
                }
            } catch (error) {
                console.log("login:", error);
                if (error.response && [404, 422].includes(error.response.status)) {
                    // This assumes the error response has a 'message' field with the error description
                    const errorMessage = error.response.data.message || 'Are you new here? Please make an account first.';
                    dispatch(showModal({ msg: errorMessage }));
                } else {
                    console.error('Login with email failed:', error);
                    // Handle other types of errors or show a generic error message
                    dispatch(showModal({ msg: 'Login failed, please try again.' }));
                }
            } finally {
                dispatch(setIsLoading(false));
            }
        })()
    };

    const handleTelegramResponse = async (response) => {
        // Handle email/password login
        try {    
            const userData = await customFetch(verifyTelegramLogin, response);
            if (userData) {
                dispatch(login(userData));
            }
        } catch (error) {
            console.log("login:", error);
            if (error.response && [404, 422].includes(error.response.status)) {
                // This assumes the error response has a 'message' field with the error description
                const errorMessage = error.response.data.message || 'Are you new here? Please make an account first.';
                dispatch(showModal({ msg: errorMessage }));
            } else {
                console.error('Login with email failed:', error);
                // Handle other types of errors or show a generic error message
                dispatch(showModal({ msg: 'Login failed, please try again.' }));
            }
        } finally {
            dispatch(setIsLoading(false));
        }
      };

	return (
        <div className="login" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <p style={{fontSize: '35px', fontWeight: 'bold'}}>AI Advice 247</p>

            <form onSubmit={handleSubmit}>
                    <div>
                        <label>Email:</label>
                        <input type="email" value={email} onChange={handleEmailChange} required />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input type="password" value={password} onChange={handlePasswordChange} required />
                    </div>
                    <button type="submit">Login</button>
            </form>
            <div style={{marginTop: '48px'}}>
                <TelegramLoginButton
                    botName="flourishingcryptobot" // Replace with your bot's username without the @ symbol
                    dataOnauth={handleTelegramResponse}
                />
            </div>
            
            <p>
                Don't have an account? 
                <span onClick={() => setIsRegistering(true)} style={{ cursor: 'pointer', color: '#007AFF', marginLeft: '0.5rem' }}>Register</span>
            </p>
        </div>
    );
};

export default Login;