import React, { useState, useEffect } from "react";
import useFetch from "../../hooks/useFetch";
import { registWithEmail } from "../../services/authServices";
import { useDispatch } from "react-redux";
import { login } from "../../features/userSlice";
import {setIsLoading, showModal} from "../../features/modalSlice";

const Register = ({ setIsRegistering, isActive }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const dispatch = useDispatch();
    const customFetch = useFetch();

	const handleSubmit = (event) => {
        (async () => {
            event.preventDefault();
            if (password === confirmPassword) {
                // Handle the signup logic here
                try {
                    const payload = {
                        username: email,
                        email: email,
                        password: password,
                        phone_number: ""
                    };
                    const data = await customFetch(registWithEmail, payload);
                    if (data) {
                        dispatch(login(data));
                    }
                } catch (error) {
                    console.error("Registration failed:", error);
                    window.alert("Registration failed, please try again.");
                } finally {
                    dispatch(setIsLoading(false));
                }
            } else {
                window.alert('Passwords do not match');
            }
        })()
    };

	return (
		<div className="register" style={{ flexDirection: 'column', alignItems: 'center' }}>
			<p style={{fontSize: '35px', fontWeight: 'bold'}}>AI Advice 247</p>
			<form onSubmit={handleSubmit}>
				<div>
                    <label>Email:</label>
                    <input type="email" value={email}  onChange={(e) => setEmail(e.target.value)} required />
                </div>
				<div>
                    <label>Password:</label>
                    <input type="password" value={password}  onChange={(e) => setPassword(e.target.value)} required />
                </div>
				<div>
                    <label>Confirm password:</label>
                    <input type="password" value={confirmPassword}  onChange={(e) => setConfirmPassword(e.target.value)} required />
                </div>
                <button type="submit">Sign Up</button>
			</form>
			<p>
				Already have an account? <span onClick={() => setIsRegistering(false)} style={{ cursor: 'pointer', color: '#007AFF', textDecoration: 'underline' }}>Login</span>
			</p>
		</div>
	);
};

export default Register;
