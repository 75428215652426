import React, { useEffect, useState } from "react";
import { dp, closeIcon, searchIcon, hamburger, chatIcon, homeIcon } from "../../assets";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoading, toggleSidebar } from "../../features/modalSlice";
import useFetch from "../../hooks/useFetch";
import SearchResults from "../SearchResults/SearchResults";
import { fetchUsersService } from "../../services/userServices";
import { fetchPostsService } from "../../services/postServices";
import "./appbar.css";
import { logout } from "../../features/userSlice";
import { setSearchQuery, setDoSearch } from '../../features/searchSlice';

const Appbar = () => {
	//global states
	const {
		user: { id, profileImage, isGuest },
		modal: { isSidebarVisible },
    search: { searchQuery: query, doSearch }
	} = useSelector(state => state);

  useEffect(() => {
    if (doSearch) searchHandler();
  }, [doSearch]);

	//local states
	const [searchResult, setSearchResult] = useState({});

	const dispatch = useDispatch();
	const customFetch = useFetch();

	const searchHandler = async (e = null) => {
    dispatch(setDoSearch(false));
		e?.preventDefault();
		if (query.length > 0) {
			// dispatch(setIsLoading(true));
			// const { posts } = await customFetch(fetchPostsService, { query, type: 'all' });
			// const { users } = await customFetch(fetchUsersService, { query });
			// setSearchResult({ posts, users });
			// dispatch(setIsLoading(false));
		}
	};

	const reset = () => {
    dispatch(setSearchQuery(""));
		dispatch(setDoSearch(false));
		setSearchResult({});
	};

	const gotoLogin = () => dispatch(logout());

  const handleSearchInput = (e) => {
    dispatch(setSearchQuery(e.target.value));
  }

	return (
		<header className={searchResult.posts || searchResult.users ? "appbar topZ" : "appbar"}>
			<div className="hamburger" onClick={() => dispatch(toggleSidebar(!isSidebarVisible))}>
				<img src={isSidebarVisible ? closeIcon : hamburger} alt="hamburger" />
			</div>
			AI Advice 247
			<form onSubmit={searchHandler} className="searchform">
				<button type="submit" aria-label="search">
					<img src={searchIcon} alt="search" />
				</button>
				<input
					type="text"
					placeholder="Tap to search..."
					value={query}
					onChange={handleSearchInput}
				/>
				<button onClick={reset} type="button" aria-label="clear search">
					<img src={closeIcon} alt="close" className="close" />
				</button>
				{(searchResult.posts || searchResult.users) && (
					<SearchResults searchResult={searchResult} reset={reset} />
				)}
			</form>
			<nav className="appbar__profile">
				{isGuest ? (
					<button className="login-btn" onClick={gotoLogin}>
						Login
					</button>
				) : (
					<button className="login-btn" onClick={gotoLogin}>
						LogOut
					</button>
				)}
			</nav>
		</header>
	);
};

export default Appbar;
